<template>
  <div>
    <a-card title="入库订单">
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />返回 </a-button>

      <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-row>
          <a-col :span="24" style="width: 320px">
            <a-form-model-item prop="external_number" label="订单号">
              <a-input v-model="form.external_number" allowClear />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="24" style="width: 320px">
            <a-form-model-item prop="area" label="库区">
              <a-select v-model="form.area" style="width: 100%">
                <a-select-option v-for="item in reservoirAreaItems" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :span="24" style="width: 320px">
            <a-form-model-item prop="order_type" label="订单类型">
              <a-select v-model="form.order_type" style="width: 100%">
                <a-select-option v-for="item in orderTypeItems" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="24" style="width: 320px">
            <a-form-model-item prop="arrival_date" label="预计到货日">
              <a-date-picker v-model="form.arrival_date" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="width: 320px">
            <a-form-model-item prop="remark" label="备注">
              <a-input v-model="form.remark" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="width: 320px">
            <a-form-model-item prop="receipt_files" label="附件">
              <receipt-file-upload v-model="form.receipt_files" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-divider></a-divider>

      <div>
        <a-row gutter="16">
          <a-col :span="24" :md="8" :xl="6" style="width: 256px">
            <a-input v-model="materialNumber" placeholder="请扫描产品编号" @keyup.enter.native="scanMaterial" allowClear />
          </a-col>
          <a-space>
            <a-button type="primary" @click="openMaterialModal">选择产品</a-button>
          </a-space>
        </a-row>

        <div style="margin-top: 16px">
          <a-table rowKey="id" size="small" :columns="columns" :data-source="goodsData" :pagination="false">
            <div slot="total_quantity" slot-scope="value, item">
              <div v-if="item.isTotal">{{ item.total_quantity }}</div>
              <a-input-number v-else v-model="item.total_quantity" size="small"></a-input-number>
            </div>
            <div slot="batch_number" slot-scope="value, item, index">
              <a-row v-if="!item.isTotal" gutter="4">
                <a-col :span="20">
                  <a-input v-model="item.batch_number" size="small"></a-input>
                </a-col>
                <a-col :span="4">
                  <a-button type="primary" icon="plus" size="small" @click="addBatch(item, index)"></a-button>
                </a-col>
              </a-row>
            </div>
            <div slot="weight" slot-scope="value, item">
              <span v-if="item.total_quantity && item.weight">
                {{ NP.times(item.total_quantity, item.weight) }}
              </span>
            </div>
            <div slot="volume" slot-scope="value, item">
              <span v-if="item.total_quantity && item.volume">
                {{ NP.times(item.total_quantity, item.volume) }}
              </span>
            </div>

            <div slot="action" slot-scope="value, item, index">
              <a-button-group v-if="!item.isTotal" size="small">
                <a-button type="danger" @click="removeMaterial(index)">删除</a-button>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <material-select-modal v-model="materialModalVisible" @select="onSelectMaterial" @confirm="onConfirm" />
  </div>
</template>

<script>
import { materialOption, orderTypeOption, reservoirAreaOption } from "@/api/option";
import { stockInOrderCreate, stockInOrderMaterials, stockInOrderRetrieve } from "@/api/order";
import moment from "moment";
import NP from "number-precision";

export default {
  components: {
    MaterialSelectModal: () => import("@/components/MaterialSelectModal/index"),
    ReceiptFileUpload: () => import("@/components/ReceiptFileUpload/index"),
  },
  data() {
    return {
      form: {
        external_number: `RK${Number(new Date())}`,
      },
      rules: {
        external_number: [
          { required: true, message: "请输入订单号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        remark: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
      },
      orderTypeItems: [],
      reservoirAreaItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "产品名称",
          dataIndex: "material_name",
        },
        {
          title: "产品编号",
          dataIndex: "material_number",
        },
        {
          title: "英文名称",
          dataIndex: "material_english_name",
        },
        {
          title: "数量",
          dataIndex: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
        },
        {
          title: "单位",
          dataIndex: "unit",
        },
        {
          title: "批次编号",
          dataIndex: "batch_number",
          scopedSlots: { customRender: "batch_number" },
        },
        {
          title: "包装规格",
          dataIndex: "spec",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      materialNumber: "",
      materialModalVisible: false,
      receiptMaterialItems: [],
      loading: false,
    };
  },
  computed: {
    goodsData() {
      // 统计合计
      let totalQuantity = 0;
      for (let item of this.receiptMaterialItems) {
        totalQuantity = NP.plus(totalQuantity, item.total_quantity);
      }
      return [
        ...this.receiptMaterialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          total_quantity: totalQuantity,
        },
      ];
    },
  },
  methods: {
    initData() {
      const receiptOrderID = this.$route.query.id;
      if (receiptOrderID) {
        stockInOrderRetrieve({ id: receiptOrderID }).then((data) => {
          const dataForm = {
            ...this.form,
            arrival_date: data.arrival_date,
            remark: data.remark,
          };

          if (data.is_void) {
            dataForm["external_number"] = data.external_number;
          }
          this.form = dataForm;
        });

        stockInOrderMaterials({ id: receiptOrderID }).then((data) => {
          const receiptMaterialItems = [];
          for (const item of data) {
            receiptMaterialItems.push({
              id: item.material,
              material: item.material,
              material_name: item.material_item.name,
              material_number: item.material_item.number,
              material_english_name: item.material_item.english_name,

              total_quantity: item.total_quantity,
              batch_number: item.batch_number,
              unit: item.material_item.unit,
              weight: item.material_item.weight,
              volume: item.material_item.volume,
              spec: item.material_item.spec,
            });
          }
          this.receiptMaterialItems = receiptMaterialItems;
        });
      }

      orderTypeOption({ page_size: 999999 }).then((data) => {
        this.orderTypeItems = data.results;
      });

      reservoirAreaOption({ page_size: 999999 }).then((data) => {
        this.reservoirAreaItems = data.results;
      });
    },
    onConfirm(items) {
      for (const item of items) {
        this.onSelectMaterial(item);
      }
    },
    onSelectMaterial(item) {
      let index = this.receiptMaterialItems.findIndex((_item) => _item.id == item.id);
      if (index != -1) {
        this.$message.warn("产品已存在");
        return;
      }
      this.receiptMaterialItems.push({
        id: item.id,
        material: item.id,
        material_name: item.name,
        material_number: item.number,
        material_english_name: item.english_name,
        total_quantity: 1,
        batch_number: `B${moment().format("YYYYMMDDHHmm")}`,
        unit: item.unit,
        weight: item.weight,
        volume: item.volume,
        spec: item.spec,
      });
      // this.receiptMaterialItems = this.$functions.insertItem(this.receiptMaterialItems, {
      //   id: item.id,
      //   material: item.id,
      //   material_name: item.name,
      //   material_number: item.number,
      //   material_english_name: item.english_name,
      //   total_quantity: 1,
      //   batch_number: `B${moment().format("YYYYMMDDHHmm")}`,
      //   unit: item.unit,
      //   weight: item.weight,
      //   volume: item.volume,
      //   spec: item.spec,
      // });
    },
    removeMaterial(index) {
      let receiptMaterialItems = [...this.receiptMaterialItems];
      receiptMaterialItems.splice(index, 1);
      this.receiptMaterialItems = [...receiptMaterialItems];
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.receiptMaterialItems.length == 0) {
            this.$message.warn("未添加产品");
            return;
          }

          for (let index in this.receiptMaterialItems) {
            let receiptMaterialItem = this.receiptMaterialItems[index];
            if (receiptMaterialItem.total_quantity === null || receiptMaterialItem.total_quantity < 0) {
              this.$message.warn(`请检查第${parseInt(index) + 1}行数量`);
              return;
            }
            if (receiptMaterialItem.batch_number.length === 0) {
              this.$message.warn(`请检查第${parseInt(index) + 1}行批次编号`);
              return;
            }
          }

          let formData = { ...this.form, stock_in_material_items: this.receiptMaterialItems };
          this.loading = true;
          stockInOrderCreate(formData)
            .then((data) => {
              this.$message.success("创建成功");
              this.$router.push({ path: "/order/stock_in_order_list" });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    scanMaterial() {
      if (!this.materialNumber) {
        this.$message.warning("请扫描产品编号！");
        return false;
      }
      this.tableLoading = true;
      materialOption({ number: this.materialNumber })
        .then((data) => {
          if (!data.results.length) {
            this.$message.warning("没有找到相关产品，请确认扫描的产品编号！");
            return false;
          }
          let item = data.results[0];
          let index = this.receiptMaterialItems.findIndex((_item) => _item.id == item.id);
          if (index != -1) {
            this.receiptMaterialItems = this.$functions.replaceItem(this.receiptMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              total_quantity: this.receiptMaterialItems[index].total_quantity + 1,
              batch_number: `B${moment().format("YYYYMMDDHHmm")}`,
              unit: item.unit,
            });
          } else {
            this.receiptMaterialItems = this.$functions.insertItem(this.receiptMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              total_quantity: 1,
              batch_number: `B${moment().format("YYYYMMDDHHmm")}`,
              unit: item.unit,
            });
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    addBatch(item, index) {
      let new_item = { ...item };
      new_item["batch_number"] = `B${moment().format("YYYYMMDDHHmm")}`;
      this.receiptMaterialItems.splice(index, 0, new_item);
    },
    openMaterialModal() {
      this.materialModalVisible = true;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style scoped></style>
